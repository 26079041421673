@import 'colors';
@import 'functions';

$primary: $itaa-blue;
$accent: $itaa-gold;

$success: $itaa-green;
$error: $itaa-red;

$body-font-family: 'Assistant';
$heading-font-family: 'Raleway';

$primary-heading-size: size(32);
$secondary-heading-size: size(26);
$tertiary-heading-size: size(18);

$primary-text-size: size(17);
$secondary-text-size: size(15);

/* Alerts */
$alert-font-size: $secondary-text-size;

/* Buttons */
$btn-border-radius: 3px;
$btn-font-weight: 600;
$btn-font-sizes: (
        'x-large': $tertiary-heading-size,
);
$btn-sizes: (
        'x-large': 50,
);

/* Chips */
$icon-outlined-border-width: thin;

/* Cards */
$card-text-font-size: $secondary-text-size;
$card-text-font-weight: 500;
$card-title-font-size: $tertiary-heading-size;
$card-title-font-weight: 700;

/* Expansion-panel */
$expansion-panel-header-padding: 16px 0;
$expansion-panel-content-padding: 0 0 16px;

/* Icons */
$icon-size-dense: 20px;

/* Labels */
$label-color: rgba($primary, 0.75);

/* Lists */
$list-item-dense-title-font-size: size(14);

/* Stepper */
$stepper-elevation: 0;
$stepper-header-elevation: 0;
$stepper-step-padding: 0;
$stepper-header-divider-margin: 0 8px;
$stepper-step-step-font-size: $tertiary-heading-size;

$stepper-label-small-font-size: 0.5rem;

/* Tooltip */
$tooltip-background-color: $itaa-blue;
$tooltip-font-size: 15px;
$tooltip-border-radius: 4px;
$tooltip-padding: 20px 29px;

$category-A-color: $itaa-teal;
$category-B-color: $itaa-purple;

$material-light: (
        'buttons': (
                'disabled': $white,
                'focused': rgba($black, 0.5) // this controls the disabled background-color for some reason,
        ),
        'input-bottom-line': $primary,
        'text': (
                'secondary': $black,
        ),
        'text-fields': (
                'outlined': $primary,
        ),
);

$headings: (
        'h1': (
                'size': $primary-heading-size,
                'line-height': size(54),
                'weight': 700,
                'letter-spacing': size(0.1),
        ),
        'h2': (
                'size': $secondary-heading-size,
                'line-height': size(39),
                'weight': 700,
        ),
        'h3': (
                'size': $tertiary-heading-size,
                'weight': 700,
                'line-height': size(28)
        ),
        'h5': (
                'size': size(13),
                'weight': 600,
        ),
        'caption': (
                'size': $tertiary-heading-size,
                'weight': 600,
                'text-transform': uppercase,
        ),
        'body-1': (
                'size': $primary-text-size,
                'weight': 400,
                'line-height': size(25),
                'letter-spacing': size(0.1),
        ),
        'body-2': (
                'size': $secondary-text-size,
                'line-height': size(22),
                'letter-spacing': size(0.1),
        ),
);
