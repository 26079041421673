@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
































.previous-next-button {
  cursor: pointer;

  > * {
    color: $grey-3;
    &:hover {
      color: $itaa-gold;
    }
  }

  &[disabled] {
    opacity: 0.5;
  }
}
