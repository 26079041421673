@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























































.file-list__file-entry {
  height: size(42);
  color: $black;
  border-bottom: size(1) solid rgba(0,0,0,.1); //om overal een scheiding te krijgen, onafhankelijk van achtergrond

  &--downloadable {
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background-color: rgba(0,0,0,.1);
      }
    }
  }
}
