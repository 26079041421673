@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























































.v-tooltip__content {
  width: 272px;
}
