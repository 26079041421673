.speedometer {
  position: relative;
}

.speedometer__hours {
  position: absolute;
  top: 55%;
  left: 50%;

  transform: translate(-50%, -50%);
  text-align: center;
}

.speedometer__text {
  font-size: 19px;
  font-weight: 700;
  color: $grey-3;

  &--accent {
    color: $itaa-green-dark;
  }
  &--single {
    color:$itaa-gold;
    &.target-achieved {
      color: $itaa-green-dark;
    }
  }
  &--category {
    color: $category-A-color;

    &.target-achieved {
      color: $itaa-green-dark;
      &--secondary{
        color:$itaa-gold;
      }
    }
  }
}

.speedometer-arc{
  &--target{
    opacity: 0.65;
  }

   &--rest, &--open {
     fill: $grey-3;
   }

   &--A{
     fill: $category-A-color;
   }
   &--B{
     fill: $category-B-color;
   }
}
