@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
















































































.evaluation-buttons__button {
  min-height: size(49);
  &--evaluating {
    max-width: size(64) !important;
    min-width: size(64) !important;
  }
}
