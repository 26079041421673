@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























































.registration-root {
  background-color: $primary;
  width: 100%;
  height: 100%;
}

.registration-root__content {
  background-color: $white;
  height: 100%;
  width: 75vw;
}

.registration-root__footer {
  height: 3rem;
  width: 75vw;
  background: $white;
}

.registration-root__footer__link {
  color: rgba($primary, 0.75) !important;
  font-size: size(13);
  line-height: size(15);
  letter-spacing: size(0.1);
}

.registration-root__language-selector {
  width: 75vw
}
