@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



































































.select-input__add-text {
  margin-top: size(2);
  color: $itaa-gold;
}
