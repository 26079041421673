.attention__jump-delayed {
    animation: 4s jump-delayed ease infinite;
}

.attention__jump-aggitated {
    animation: 2s jump-delayed ease infinite alternate;

}


@keyframes jump {
  0% {
    transform: scale(1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
  }

  100% {
    transform: scale(1.01);
    box-shadow: 0 4px 20px rgba(0, 0, 0, .1);
  }
}

@keyframes jump-delayed {

  0%,90%,100% {
    transform: scale(1);
    box-shadow: none;
  }

  95% {
    transform: scale(1.01);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, .10);
  }
  
}