.custom-input{
    padding-top: 0;
    margin-top: 0;
}

.cursor-pointer{
    cursor: pointer;
}

.position-relative{
    position: relative;
}

.position-absolute{
    position: absolute;
}

.w-100{
    width:100%;
}

.h-100 {
    height: 100%;
}

@for $i from 1 through 5 {
    .gap-#{$i} > {
        :not(:last-child) {
        margin-bottom: $i*4px;
        }
    }
}

.table__header {
    &--subject {
        min-width: 200px;
    }
}

.color {
    &-grey {
        &-3 {
            color: $grey-3 !important;
            fill: $grey-3 !important;
        }
    }
}
