@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';




































































































.category-speedometer__legend {
  left: 0.75rem;
  top: 0.75rem;
  color: $black;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.1px;
}

.category-speedometer__legend__sphere {
  height: 21px;
  width: 21px;
  border-radius: 50%;

  &--A {
    background-color: $category-A-color;
  }

  &--B {
    background-color: $category-B-color;
  }
}
