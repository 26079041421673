@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


































.checkbox-align-top {
  .v-input--selection-controls__input {
    align-self: baseline;
  }
}
