@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';














.main {
  height: 100vh;
}

.v-toast__item--warning {
  background-color: var(--v-secondary-base) !important;
}
