@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';























































































































































































































































































.data-table-pointer {
  > * tr {
    cursor: pointer;
  }
}

.table__header {
  &--totalHoursYearly,
  &--totalHoursThreeYearly {
    background-color: rgba($primary, 0.1);
  }
}

.annual-report-history-table__period-headers__divider {
  background-color: $primary;
}

.annual-report-history-table__column {
  height: 100%;

  &--highlight {
    background-color: rgba($primary, 0.1);
  }
}

.annual-report-history-table__data {
  &--notValid {
    color: $itaa-red;
  }
}
