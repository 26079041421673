@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



































































.select-file-button {
  position: relative;
  overflow: hidden;
  display: inline-block;

  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
