@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
































































































.activity-creation__stepper-content {
  min-height: size(644);
}

.v-stepper__wrapper {
  // prevent box-shadows from being cut off
  overflow: visible;
}
