@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';






































































































































































































.disciplines-list .v-list-group--active > .v-list-group__header > .v-list-group__header__prepend-icon .v-icon {
  transform: rotate(90deg);
}

.disciplines-list .v-list-item__title {
  white-space: normal;
}

.disciplines-list__sub-group {
  margin-left: 70px;
}

.disciplines-list__subheader {
  border-top: 1px solid $itaa-blue;
  color: $itaa-blue !important;
}
