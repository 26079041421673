@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


























































































.documents-for-recognition__alert--bottom-spacing {
  margin-bottom: 38px;
}
