@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';






































































































































































































































































































































































































.create-activity {
  .v-stepper__wrapper {
    // prevent box-shadows from being cut off
    overflow: visible;
  }
}
