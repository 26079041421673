@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';










































































































.itaa-dialog__title {
  color: $itaa-gold;
}

.newlines {
  white-space: pre-line;
}
