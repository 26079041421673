@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';















































































































































































































.info-label {
  color: $itaa-blue;
  opacity: 0.5;
}

.tooltip-text {
  opacity: 0.5;
  text-decoration: underline;
}
