@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';




































































































































































.recognition-list {
  .v-expansion-panel.recognition-list__panel {
    border: 1px solid $grey-5;
    border-collapse: collapse;

    .v-expansion-panel-header {
      color: $grey-5;
    }

    &:not(:last-child) {
      border-bottom: none;
    }
  }

  .v-expansion-panel--active {
    border-bottom-width: 1px !important;
    border-bottom-style: solid !important;

    + .v-expansion-panel {
      border-top: none;
    }

    &.recognition-list__panel {
      &--Pending,
      &--Active {
        border-color: $primary !important;

        .v-expansion-panel-header {
          color: $primary;
          background-color: mix($primary, $white, 12%);
        }
      }

      &--Expired,
      &--Rejected {
        border-color: $error !important;

        .v-expansion-panel-header {
          color: $error;
          background-color: mix($error, $white, 12%);
        }
      }

      &--Approved {
        border-color: $success !important;

        .v-expansion-panel-header {
          color: $success;
          background-color: mix($success, $white, 12%);
        }
      }
    }
  }
}
