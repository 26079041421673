@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';























































.members-filter__form {
  max-width: 50rem;
}
