@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';





















































































































































.import-table__row--error {
  color: $itaa-red;
  cursor: pointer;
}

.import-table__row--warning {
  color: $itaa-warning;
  cursor: pointer;
}
