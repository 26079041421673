@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

























































































































.calendar-activity-card {
  padding-top: 32px;
  height: 100%;
}

.v-application .calendar-activity-card .text-caption {
  font-size: size(17) !important;
}

.calendar-activity-card__hours {
  background-color: $itaa-blue;
  color: $white;
  float: right;
  margin-top: -33px; //-1 times the padding-top of the activity card + 1px (border width)
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  min-width: size(54);
}

.calendar-activity-card__secondary-text {
  color: #708ba5;
}

.calendar-activity-card__date {
  height: 2rem;
}
