@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';




















































































































































.data-table-pointer {
  > * tr {
    cursor: pointer;
  }
}
