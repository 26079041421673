@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























.activity-state{

    &--Approved {
        color: $itaa-green;
    }
    &--AwaitingApproval {
        color: $grey-7;
    }
    &--Rejected {
        color: $itaa-red;
    }
}
