@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


































































































































































































































.activity-calendar__filter-and-calendar {
  border-top: solid size(1) $grey-1;
}

.activity-calendar__search-bar {
  .v-input__control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.activity-calendar__search-button {
  min-height: size(56);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
