@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



















































































































































.import-activities__empty-state__image {
  max-width: 374px;
}
