@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';






























































































































































.user-management-lady-svg {
  width: size(296);
  height: size(173);
}
