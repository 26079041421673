@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';





























































































































































.colored-chips {
  .v-chip {
    background-color: $primary !important;
    color: $white;
  }
}
