@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';















































.chip-selector-base {
  gap: 0.5rem;
}
.chip-selector-base__chip {
  cursor: pointer;
}
