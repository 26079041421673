@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';





















































































































































































































































































































.recognition__stepper-content {
  min-height: size(688);
}
