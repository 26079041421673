@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';





























































































































































































.activity-evaluation__empty-state {
  width: size(328);
  height: size(346);
}
