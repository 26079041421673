@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';











































































































.time-range-input {
  .v-picker--time:first-child {
    .v-picker__title {
      border-top-right-radius: 0;
    }
  }

  .v-picker--time:last-child {
    .v-picker__title {
      border-top-left-radius: 0;
    }
  }
}
