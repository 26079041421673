@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';





























































































.activity-card-container__results-text {
  font-weight: 700;
  font-size: size(19);
  line-height: size(28);
  letter-spacing: size(0.1);
  color: $grey-3;
  font-family: $heading-font-family;
}

.activity-card-container__clear-filters {
  opacity: 0.5;
}

.activity-card-container__empty-state {
  max-height: 28rem;
  min-height: 28rem;
}
