@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';




























































































































.file-input__button,
.file-input__file-entry,
.file-input__file-list {
  width: 100%;
}

.file-input__file-entry {
  min-height: 40px;
  color: $black;
}

.file-input__outline {
  z-index: 1;
}
