@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';





































.activity-badge {
  padding: size(4) size(10);
  margin-right: size(6);
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  color: $itaa-blue;
}
