@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



















.category-sphere {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  // TODO: move to inline-flex
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;

  &--A {
    background-color: $category-A-color;
  }

  &--B {
    background-color: $category-B-color;
  }

  &--selectable {
    cursor: pointer;
    @media (hover: hover) {
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &--selected {
    transform: scale(1.1);
  }

  &--disabled {
    transform: scale(0.95);
    background-color: rgba(0, 0, 0, 0.54);
    opacity: 0.5;
  }
}
