@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';






















































.register {
  background-color: $primary;
  width: 100%;
  height: 100%;
}

.register__content {
  background-color: $white;
  height: 100%;
}
