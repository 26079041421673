@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';













































.selection-card--selected {
  background-color: rgba(var(--v-secondary-base), 0.2);
}

.selection-card__selected-indicator {
  height: size(24);
  width: size(24);
  border: 1px solid $grey-3;
  border-radius: size(12);

  .selection-card:hover & {
    background-color: $grey-3;
  }

  .selection-card--selected & {
    background-color: $accent !important;
    border-color: $accent !important;
  }
}
