.v-btn.v-size--x-large {
  min-width: 226px !important;
}

/* Alerts */
.v-alert {
  &__content {
    font-weight: 600;
  }
}

/* Chips */
.v-chip {
  border-width: $icon-outlined-border-width;
  border-style: solid;
}

/* Cards */
.v-card__title {
  font-family: $heading-font-family;
  padding-bottom: 0;
}

.v-application {
  .v-card {
    &.accent {
      background-color: mix($accent, $white, 20) !important;
    }
  }
}

/* Icons */
.v-icon svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
}

/* Labels */
.theme--light.v-label {
  color: rgba($primary, 0.75);
}

/* Tooltip */
.v-tooltip__content.menuable__content__active {
  opacity: 0.7 !important;
}

/* v-tabs */
.v-tabs-thick {
  .v-tab {
    background-color: rgba($primary, 0.5);
    font-weight: 600;
    font-size: size(19);
    letter-spacing: size(0.1);
    border-bottom: size(3) solid $primary;
    padding-left: 3rem;
    padding-right: 3rem;
    &--active {
      background-color: $primary;
      color: $white !important;
    }
  }
}

.v-autocomplete.notransform.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}

/* v-data-table */
.v-data-table-header th {
  text-transform: uppercase;
  font-weight: 600;
}

/* buttons */
.theme--light.v-btn--disabled.v-btn--outlined {
  color: currentColor !important;
  opacity: 0.5;
}

.wrapping-button.v-btn {
  &.v-size--default {
    height: auto;
    min-height: 36px;
  }

  .v-btn__content {
    width: 100%;
    white-space: normal;
    text-align: center;
  }
}
