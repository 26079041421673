@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';







































































































.disciplines-selector__content {
  width: 100%;
}

.disciplines-selector__label {
  color: rgba($primary, 0.5);
}
