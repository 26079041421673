<template>
  <v-data-table :headers="headers"
                class="activity-history__table data-table-pointer"
                @click:row="navigateToDetails"
                :items="items.result"
                :item-class="rowClass"
                :disable-sort="true"
                :page="page"
                :items-per-page="pageSize"
                :server-items-length="totalItems"
                @update:page="($event) => onPaginationChanged($event, pageSize)"
                @update:items-per-page="($event) => onPaginationChanged(page, $event)"
                :loading="loadingData"
                :loading-text="$t('activityHistory.activityTable.isLoading')"
                :header-props="{
                  'sort-by-text':$t('activityHistory.activityTable.sortBy')
                }"
                :footer-props="{
                  'items-per-page-text' : $t('activityTable.itemsPerPageText'),
                  'items-per-page-all-text' : $t('activityTable.showAllPages'),
                  'items-per-page-options': [10,20,30,50]
                }"
                :expanded.sync="expanded"
                show-expand>
    <template #footer.page-text="{pageStart, pageStop, itemsLength}">
      {{ $t('activityTable.pageFromToActivities', { start: pageStart, end: pageStop, total: itemsLength }) }}
    </template>
    <template #no-data>
      <div class="text-h3 py-10">
        {{ $t('activityHistory.activityTable.noDataYet') }}
      </div>
    </template>
    <template #item.activityDate="{item}">
      {{ item.activityDate | formatDate }}
    </template>
    <template #item.educationalActivityType="{item}">
      {{ $t(`activities.type.${item.educationalActivityType}.title`) }}
    </template>
    <template #item.hours="{item}">
      {{ item.memberParticipation.accountableHours | formatDuration }}
    </template>
    <template #item.category="{item}">
      <div :class="`category-sphere category-sphere--${item.memberParticipation.category}`">
        {{ item.memberParticipation.category }}
      </div>
    </template>
    <template #item.submittedBy="{ item }">
      <div class="d-flex align-center">
        <role-icon class="mr-3"
                   :role="getSubmittedByRole(item)" />
        {{ getSubmittedBy(item) }}
      </div>
    </template>
    <template #item.state="{item}">
      <activity-state-badge :state="item.state" />
    </template>
    <template #item.data-table-expand="{ item, isExpanded, expand }">
      <v-icon
        v-if="item.memberParticipation.isAdapted"
        :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
        @click.stop="expand(!isExpanded)"
        @click="onExpand(!isExpanded, item)">
        $expand
      </v-icon>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <tr v-for="(history, id_a) in item.memberParticipation.history"
          :key="id_a"
          class="expanded_tr">
        <td />
        <td class="text-start">
          {{ history.created | formatDate }}
        </td>
        <td class="text-start">
          {{ item.subject }}
        </td>
        <td class="text-start">
          {{ history.motivation }}
        </td>
        <td class="text-start">
          <div v-for="(value, key) in history.changes"
               :key="key">
            <div v-if="key === 'history.hours'">
              {{ value | formatDuration }}
            </div>
          </div>
        </td>
        <td class="text-start">
          <div :class="`category-sphere category-sphere--${item.memberParticipation.category}`">
            {{ item.memberParticipation.category }}
          </div>
        </td>
        <td class="text-start">
          <div class="d-flex align-center">
            <role-icon class="mr-3"
                       :role="getSubmittedByRole(item)" />
            {{ getSubmittedBy(item) }}
          </div>
        </td>
        <td class="text-start">
          <activity-state-badge :state="item.state" />
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script lang="ts">

import ActivityStateBadge from '@/components/activities/ActivityStateBadge.vue';
import RoleIcon from '@/components/general/RoleIcon.vue';
import { Role } from '@/contracts/role.enum';
import { IApiEducationalActivityBase } from '@server/education/contracts/api/educational-activity.api-contract';
import { IPagedResult } from '@server/general/contracts/paged-result';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

@Component({
  components: { RoleIcon, ActivityStateBadge },
})
export default class MemberActivityTable extends Vue {
  @Prop()
  public items!: IPagedResult<IApiEducationalActivityBase>;

  @Prop()
  public readonly page!: number;

  @Prop()
  public readonly pageSize!: number;

  @Prop()
  public readonly loadingData!: boolean;

  public expanded: IApiEducationalActivityBase[] = [];

  public navigateToDetails(activity: IApiEducationalActivityBase) {
    if (activity?.memberParticipation?.isAdapted) {
      this.$router.push({
        name: this.Routes.ActivityDetails,
        params: { activityId: activity.id },
        query: { participationId: activity?.memberParticipation?._id?.toString() },
      });
    } else {
      this.$router.push({ name: this.Routes.ActivityDetails, params: { activityId: activity.id } });
    }
  }

  public get headers(): DataTableHeader[] {
    return [
      {
        text: '',
        value: 'data-table-expand',
      },
      {
        text: this.$t('activityHistory.activityTable.activityDate').toString(),
        value: 'activityDate',
      },
      {
        text: this.$t('activityHistory.activityTable.subject').toString(),
        value: 'subject',
        class: 'table__header--subject',
      },
      {
        text: this.$t('activityHistory.activityTable.educationalOperator').toString(),
        value: 'educationalOperator.operatorName',
      },
      {
        text: this.$t('activityHistory.activityTable.hours').toString(),
        value: 'hours',
      },
      {
        text: this.$t('activityHistory.activityTable.category').toString(),
        value: 'category',
      },
      {
        text: this.$t('activityHistory.activityTable.submittedBy').toString(),
        value: 'submittedBy',
      },
      {
        text: this.$t('activityHistory.activityTable.state').toString(),
        value: 'state',
      },
    ];
  }

  public get totalItems() {
    return this.items.total ?? 100000;
  }

  public rowClass(item: IApiEducationalActivityBase) {
    if (item.memberParticipation?.isAdapted && item.memberParticipation.category === 'A') return 'row_adapted_A';
    if (item.memberParticipation?.isAdapted && item.memberParticipation.category === 'B') return 'row_adapted_B';
  }

  public onExpand(expanded: boolean, item: IApiEducationalActivityBase): void {
    if (expanded && item.memberParticipation && !item.memberParticipation?.history) {
      this.$set(this.expanded, this.expanded.indexOf(item), item);
    }
  }

  public onPaginationChanged(page: number, pageSize: number): void {
    this.$emit('page-change', {
      page,
      pageSize,
    });
  }

  public getSubmittedBy(item: IApiEducationalActivityBase): string {
    return (!item.isMemberActivity && item.educationalOperator?.operatorName) ||
      item.submittedBy?.name ||
      this.$t('user.deletedUser').toString();
  }

  public getSubmittedByRole(item: IApiEducationalActivityBase): Role {
    return item.isMemberActivity ? Role.Member : Role.CaseManager;
  }
}
</script>

<style>
.v-data-table__expanded__content {
  display: contents;
}

tr .expanded_tr {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.expanded_tr td {
  font-size: 0.875rem;
  height: 48px;
  padding: 0 16px;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.row_adapted_A {
  background: rgba(34, 146, 164, 0.17);
}

.row_adapted_B {
  background: rgba(152, 136, 165, 0.17);
}
</style>
