@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
























































































































.active-recognition-summary {
  background-color: $itaa-blue;
  border-radius: 4px;
  color: white;
}

.no-active-recognition-summary {
  border-radius: 4px;
  background-color: mix($primary, $white, 12%);
  color: $itaa-blue;
  min-height: size(185);
}
