.tertiary-heading-size {
  font-size: $tertiary-heading-size;
}

.pre-line {
  white-space: pre-line;
}

.label {
  color: $label-color;
}

.large-text{
  font-size: size(19);
  font-family: $body-font-family;
  line-height: size(28);
  color: $black;
}

.font-weight-medium-bold {
  font-weight: 600;
}

.text-inactive {
  opacity: 0.54;
}

.primary-400--text {
  color: $primary-400 !important;
}

.filter-title {
  font-weight: 500;
  font-size: size(17);
  line-height: size(21);
  letter-spacing: size(0.1);
  color: $grey-3;
  text-transform: uppercase;
  margin-bottom: 1rem;
}