@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























































































































.recognition-evaluation__empty-state {
  width: size(500);
  height: size(400);
}
