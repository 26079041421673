@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
























































































































































































































































.activity-details__context-menu-button {
  min-height: size(50) !important;
  min-width: size(50) !important;
  max-height: size(50) !important;
  max-width: size(50) !important;
  border: size(1) solid $black;
}
