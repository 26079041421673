@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';










































































.educational-speaker-input__educational-speaker-status {
  font-weight: bold;
  font-size: size(13);
  line-height: size(15);
  letter-spacing: size(0.1);
  color: $itaa-gold;
  text-transform: uppercase;
}
