@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';





























































































































































































.speedometer-base {
  width: 250px;
}

.gauge__arc__text {
  font-size: 17px;
  fill: white;
}

.speedometer__container__svg {
  // height: 220px;
  // width: 250px;
}
