@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';




























































































.activity-evaluation-filter__educational-operator-filter {
  max-width: 30rem;
  min-width: 15rem;
}

.recognition-evaluation-filter__chip-selector-base{
  height: 2.5rem;
}
