@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';















































































































































































































.activity-calendar-filter__filter-button {
  box-shadow: 0 -0.1rem 0.1rem 0 $grey-3;
  background-color: $grey-1;
  position: sticky;
  bottom: 0;
  border-top: solid size(2) $grey-3;
  .v-btn.v-size--x-large {
    min-width: 0 !important;
  }
}

.activity-calendar-filter__date-picker {
  border: solid size(1) $grey-3;
}

.activity-calendar-filter__date-picker-clear {
  opacity: 0.5;
}
