@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';







































.overlay-blur {
  filter: blur(0.25rem);
}

.background-hidden {
  opacity: 0;
}

.loading-overlay__content {
  height: 100%;
}

.loading-overlay__container {
  height: 100%;
}
