@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';




















































































.role-selection {
  height: 100%;
}
