@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
























































































































































































.activity-history-tabs__tabs {
  background-color: $primary;
  color: white;
}

.activity-history-tabs__tabs__tab {
  background-color: $accent;
}

.activity-history-tabs__divider {
  background-color: $primary;
}

.activity-history-tabs__tab-item__disciplines-list {
  color: transparent !important;
}

.activity-history-tabs__tab-item__title {
  font-size: large;
  font-weight: 600;
  max-width: 35rem;
}
