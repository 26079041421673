@import '../../node_modules/vue-toast-notification/dist/theme-sugar.css';


.v-toast__item{
  max-width: size(320);
  font-family: $body-font-family;
  align-items: flex-start;
  padding: 1rem;
  // opacity: 0.95;
  box-shadow: 0rem 0.25rem 0.5rem 0.05rem rgba(0,0,0, 0.5);
  &--error{
    background-color: $itaa-red;
  }
  .v-toast__text {
    margin: 0;
    padding: 0;
  }
  .v-toast__icon{
    margin: 0;
    margin-right: 1rem;
  }
}

