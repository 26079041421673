@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


































































.input-base {
  color: $primary;
}
