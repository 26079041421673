@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

























































.no-recognitions-yet__content {
  min-height: size(320);
  max-height: size(400);
}
