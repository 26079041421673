@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';







































































.recognition-history__expansion-panel {
  z-index: unset;
}
