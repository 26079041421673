.content {
  background-color: $grey-1;
  margin-top: size(50);
  padding: size(60) size(46) size(30)
}

li {
  margin: 1em 0;
  &::marker{
    font-size: small;
  }

}

.stick-to-bottom {
  position: sticky;
  bottom: 0;
}

.fill-width {
  /** css hack to not let the element expand the parent's width but take op all available space */
  width: 0;
  min-width: 100%;
}