@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';










































































































































































































































































































.navigation {
  font-size: $tertiary-heading-size;
  font-weight: 500;
}

.main-container {
  height: 100%;
}

.right-side-bar {
  width: size(280);
}

.right-app-drawer {
  overflow: hidden;
  width: 0;
  height: 100%;
  background-color: $grey-1;
  box-shadow: inset 0 0 size(8) rgba(0, 0, 0, 0.25);
  transition: width 200ms;

  &--open {
    width: size(300);
  }
}

.v-application .privacy-and-cookie-statement a {
  color: #708ba5 !important;
  font-size: size(13);
  line-height: size(15);
  letter-spacing: size(0.1);
  text-align: center;
  display: block;
}
